import './SongList.css'

export default function SongList() {
    return (
        <main>
            <h1 className='song-list-header'>Song List:</h1>
            <h2 className="song-list">
                <div className='item'>
                    The Ataris - The Boys of Summer
                </div>
                <div className='item'>
                    Backstreet Boys - Everybody (Backstreet's Back)
                </div>
                <div className='item'>
                    Blink-182 - All The Small Things
                </div>
                <div className='item'>
                    Buckcherry - Crazy Bitch
                </div>
                <div className='item'>
                    Bush - Comedown,
                    Machine Head
                </div>
                <div className='item'>
                    Candlebox - Far Behind
                </div>
                <div className='item'>
                    Cee Lo Green - Forget You
                </div>
                <div className='item'>
                    Collective Soul - Shine
                </div>
                <div className='item'>
                    Creed - Higher,
                    One Last Breath
                </div>
                <div className='item'>
                    Disturbed - Down With The Sickness
                </div>
                <div className='item'>
                    Eve 6 - Inside Out
                </div>
                <div className='item'>
                    Fall Out Boy - Sugar, We’re Going Down
                </div>
                <div className='item'>
                    Finger Eleven - Paralyzer
                </div>
                <div className='item'>
                    Foo Fighters - Everlong,
                    Learn To Fly,
                    My Hero
                </div>
                <div className='item'>
                    Fuel - Hemorrhage
                </div>
                <div className='item'>
                    Goo Goo Dolls - Slide
                </div>
                <div className='item'>
                    Good Charlotte - The Anthem
                </div>
                <div className='item'>
                    Green Day - Basket Case,
                    When I Come Around
                </div>
                <div className='item'>
                    Guns N’ Roses - Sweet Child O’ Mine
                </div>
                <div className='item'>
                    Jimmy Eat World - The Middle
                </div>
                <div className='item'>
                    The Killers - Mr. Brightside
                </div>
                <div className='item'>
                    Linkin Park - In The End
                </div>
                <div className='item'>
                    Lit - My Own Worst Enemy
                </div>
                <div className='item'>
                    Lynyrd Skynyrd - Free Bird
                </div>
                <div className='item'>
                    Metallica - Enter Sandman,
                    For Whom The Bell Tolls
                </div>
                <div className='item'>
                    Nickelback - Rockstar
                </div>
                <div className='item'>
                    Nirvana - Come As You Are
                </div>
                <div className='item'>
                    New Found Glory - My Friends Over You
                </div>
                <div className='item'>
                    Papa Roach - Last Resort
                </div>
                <div className='item'>
                    Pearl Jam - Even Flow
                </div>
                <div className='item'>
                    Poison - Nothin’ But A Good Time
                </div>
                <div className='item'>
                    Rage Against The Machine - Bulls On Parade,
                    Killing In The Name
                </div>
                {/* <div className='item'>
                    Red Hot Chili Peppers - By The Way,
                    Can't Stop,
                    Otherside
                </div> */}
                <div className='item'>
                    Shaggy - Angel,
                    It Wasn't Me
                </div>
                <div className='item'>
                    Smashing Pumpkins - Cherub Rock
                </div>
                <div className='item'>
                    Stone Temple Pilots - Interstate Love Song,
                    Plush,
                    Vasoline
                </div>
                <div className='item'>
                    Sum 41 - Fat Lip,
                    In Too Deep
                </div>
                <div className='item'>
                    Third Eye Blind - Jumper
                </div>
                <div className='item'>
                    Three Days Grace - Animal I Have Become
                </div>
                {/* <div className='item'>
                    Temple of the Dog - Hunger Strike
                </div> */}
                <div className='item'>
                    Tool - Sober
                </div>
                <div className='item'>
                    Van Halen - You Really Got Me
                </div>
                <div className='item'>
                    3 Doors Down - Kryptonite
                </div>
                <div className='item'>
                    Usher - Yeah!
                </div>
                <div className='item'>
                    The Weeknd - Blinding Lights
                </div>
            </h2>
            <br />
        </main>
    )
}
