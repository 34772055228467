import './Video.css'
// import video from './EPK.mp4'
import ReactPlayer from 'react-player'

export default function Video() {
    return (
        <main>
            <div>
                <ReactPlayer
                    className='react-player'
                    url={`https://youtu.be/zjHZg4N-9mk`}
                    // width='100%'
                    // height='100%'
                    controls={true}
                />
                {/* <div class="iframe-container">
                <iframe width="80%" height="70%" url="https://youtu.be/zjHZg4N-9mk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div> */}
            </div>
            <div className='space'>_</div>
        </main>
    )
}